import React, {
    useState, useEffect
} from 'react';

import {
    login,
    log_out
} from './login';

import {
    SetActivePanelButton
} from './pane_sidebar';

import {
    maintenance
} from './maintenance';

import {
    refresh_data
} from './refresh_data';

import {
    refresh_page
} from './refresh_page';

import './css/app.css';
import './css/mobile.css';
import './css/desktop.css';

import {
    get_status
} from './api_strich_calls';

import {
    set_cookie,
    get_cookie,
} from './functions_cookies';

import {
    display_confirmation_dialog,
    display_confirmation_dialog_single_button_with_response
} from './functions_dialog';



function App() {

    //set_cookie("active_panel_cookie", "Dashboard", 1);

    function reset_data() {

        // Burn them. Burn them all!
        // clear all the data down on log off

        // Legacy

        // Strich

        // Active elements
        if (!(active_panel === "Dashboard")) { set_active_panel("Dashboard") }

        //page layout - close or open the sidebar on logout depending on device_type
        if (toggle_sidebar && device_type === "mobile") { set_toggle_sidebar(false) }
        if (!toggle_sidebar && device_type === "desktop") { set_toggle_sidebar(true) }


        // Reset the refresh flags
        if (!refresh_flags.account_details) { set_refresh_flag({ ...refresh_flags, account_details: true }) }
        if (!refresh_flags.ai_queue) { set_refresh_flag({ ...refresh_flags, ai_queue: true }) }
        if (!refresh_flags.ai_to_process) { set_refresh_flag({ ...refresh_flags, ai_to_process: true }) }

        // Reset the changed flags
        if (changed_flags.unsettled_earnings) { set_changed_flag({ ...changed_flags, unsettled_earnings: false }) }
    }

    // Refresh flags
    const [refresh_flags, set_refresh_flag] = useState({
        account_details: true,
        ai_queue: true,
        ai_to_process: true,
        ai_styles: true
    });

    // Changed flags - used to record if the data has changed
    const [changed_flags, set_changed_flag] = useState({
        account_details: false,
        ai_queue: false,
        ai_to_process: false,
        ai_styles: false
    });

    // Timers for the refresh flags
    const [refresh_timer, set_refresh_timer] = useState(20);

    // Key data
    const [token, set_token] = useState(false);
    const [active_panel, set_active_panel] = useState('Dashboard');
    const [account_details, set_account_details] = useState([]);
    const [ai_queue, set_ai_queue] = useState([]);
    const [ai_to_process, set_ai_to_process] = useState([]);
    const [ai_styles, set_ai_styles] = useState([]);

    const [first_log_in, set_first_log_in] = useState(false);

    //empty array for custom days earnings
    //const [custom_days_earnings, set_custom_days_earnings] = useState([]);

    //set a boolean state for whether the dialog has been seen yet
    const [has_to_do_list_dialog_been_seen, set_has_to_do_list_dialog_been_seen] = useState("-");


    //set state for showing sidebar menu
    const [toggle_sidebar, set_toggle_sidebar] = useState(true);
      

    // Timer that counts down and sets the refresh flags to keep the dashboard up to date
    useEffect(() => {

        // Decrease the timer every second
        refresh_timer > 0 && setTimeout(() =>
            set_refresh_timer(refresh_timer - 1), 1000,
        );

        // If at 0, reset the timer and set the refresh flags
        if (refresh_timer === 0) {

            // Reset the timer
            set_refresh_timer(60)

            // Reset each of the flags

            // Strich
            // refresh_flags.set_ai_to_process = true
            

            // Update the array
            // Note when you update an array using ...array, that will hold the previous settings
            // If you don't update those values locally, it will update with the old ones
            // Doesn't matter if you're just updating one, but when you're doing multiple, like here
            // It's best to set them all and then refresh all at once like below

            set_refresh_flag({
                ...refresh_flags
            });

        };


    }, [refresh_timer, refresh_flags]);


    // Timers for the refresh queue flag
    const [refresh_queue_timer, set_refresh_queue_timer] = useState(20);

    // Timer that resets the queue flag - we need this updating a bit quicker
    useEffect(() => {

        // Decrease the timer every second
        refresh_queue_timer > 0 && setTimeout(() =>
            set_refresh_queue_timer(refresh_queue_timer - 1), 1000,
        );

        // If at 0, reset the timer and set the refresh flags
        if (refresh_queue_timer === 0) {

            // Reset the timer
            set_refresh_queue_timer(3)

            // Reset each of the flags

            // Strich
            refresh_flags.ai_queue = true
            refresh_flags.set_ai_to_process = true


            // Update the array
            // Note when you update an array using ...array, that will hold the previous settings
            // If you don't update those values locally, it will update with the old ones
            // Doesn't matter if you're just updating one, but when you're doing multiple, like here
            // It's best to set them all and then refresh all at once like below

            set_refresh_flag({
                ...refresh_flags
            });

        };


    }, [refresh_queue_timer, refresh_flags]);


    //-----------------------------------------
    // CHECK IF THE PORTAL IS OFFLINE/ONLINE //

    //set a refresh timer for checking the portal status (run every 10 seconds)
    const [refresh_status_timer, set_refresh_status_timer] = useState(10);

    //function to check the portal status
    const check_portal_status_and_refresh = () => {

        //run api call to check status
        get_status(token, set_token);

        //console.log(token);
    };

    //useEffect loop to run every 10 seconds
    useEffect(() => {

        const interval = setInterval(() => {

            //count down the timer
            set_refresh_status_timer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 10);

            //if it's 0
            if (refresh_status_timer === 0) {

                //set it back to 10 seconds
                set_refresh_status_timer(10);

                //run the function to check the portal status
                check_portal_status_and_refresh();

            }
        }, 10000); // 10000 (milliseconds) for a 10-second interval

        //run the check function on initial mount
        check_portal_status_and_refresh();

        return () => clearInterval(interval);

    }, [refresh_status_timer, token]); //useEffect dependancies monitor refresh timer and token

    //-----------------------------------------






    //-----------------------------------------

    //make a state for whether we're using mobile or desktop view
    //check screen size mobile = <1179px desktop = >1179px
    const [device_type, set_device_type] = useState(true);

    //use react effect to check if the screen has been resized less than 1180px
    useEffect(() => {

        function handle_screen_resize_to_get_device_type() {

            if (window.innerWidth < 1180) {

                //set device to mobile
                set_device_type("mobile");

                //if so, close the sidebar
                set_toggle_sidebar(false);
            }

            else {

                //set device to desktop
                set_device_type("desktop");

                //open the sidebar
                set_toggle_sidebar(true);

            }

        }

        handle_screen_resize_to_get_device_type();

        window.addEventListener('resize', handle_screen_resize_to_get_device_type);

        return () => {
            window.removeEventListener('resize', handle_screen_resize_to_get_device_type);
        };
    }, []);

    //-----------------------------------------








    const BuildPage = () => {


        //*****************************************************************
        //*****************************************************************
        //get_status(token, set_token);
        //*****************************************************************
        //*****************************************************************


        // Refresh data
        refresh_data(
            token,
            set_token,
            refresh_flags,
            set_refresh_flag,
            changed_flags,
            set_changed_flag,
            account_details,
            set_account_details,
            ai_queue,
            set_ai_queue,
            ai_to_process,
            set_ai_to_process,
            set_ai_styles
        )

        //check if token is false (they've refreshed the page or it's first page load)
        if (token === false) {

            //get the url parameters
            const url_parameter = new URLSearchParams(window.location.search);

            //check if it's a link for resetting the password, contact email or Paypal email or sign up or active account
            if (url_parameter.get('action') === "reset_password" || url_parameter.get('action') === "update_email" || url_parameter.get('action') === "update_paypal" || url_parameter.get('action') === "activate_account" || url_parameter.get('action') === "sign_up") {

                //it is, so clear the log in cookie - we don't want to log them in
                set_cookie("log_in_cookie", "", "60");

            }

            //get the log in cookie value
            var log_in_cookie_value = get_cookie("log_in_cookie");

            //get the active page cookie value
            var active_panel_cookie_value = get_cookie("active_panel_cookie");

            //console.log("cookie_value = ", log_in_cookie_value);

            if (log_in_cookie_value === undefined || log_in_cookie_value === "") {

                //there's no log in cookie so don't log them in

            } else {

                //there's a log in cookie

                //set the token to the cookie value (refreshing page sets token to false)
                set_token(log_in_cookie_value);

                    //set the active panel to whatever the last active panel was before the refresh or 'Dashboard' by default
                    if (active_panel_cookie_value === undefined) {

                        set_active_panel("Dashboard");

                    } else {

                        set_active_panel(active_panel_cookie_value);

                    };
                               
                // Refresh the page contents
                return refresh_page(
                    token,
                    set_token,
                    refresh_flags,
                    set_refresh_flag,
                    changed_flags,
                    set_changed_flag,
                    active_panel,
                    set_active_panel,
                    toggle_sidebar,
                    set_toggle_sidebar,
                    device_type,
                    set_device_type,
                    account_details,
                    set_account_details,
                    ai_queue,
                    set_ai_queue,
                    ai_to_process,
                    set_ai_to_process,
                    ai_styles
                );

            }

        }


        //check if the token is 'offline' - if so show the maintenance page
        if (token === "offline") {

            //reset_data();
            //console.log("it's offline - show maintenance page")
            return maintenance();

        }



        // Check if we have a token - if not, give login screen
        if (!token) {

            reset_data();

            return login(set_token, set_has_to_do_list_dialog_been_seen, set_first_log_in);
                        
        }



        // Refresh the page contents
        return refresh_page(
            token,
            set_token,
            refresh_flags,
            set_refresh_flag,
            changed_flags,
            set_changed_flag,
            active_panel,
            set_active_panel,
            toggle_sidebar,
            set_toggle_sidebar,
            device_type,
            set_device_type,
            account_details,
            set_account_details,
            ai_queue,
            set_ai_queue,
            ai_to_process,
            set_ai_to_process,
            ai_styles
        );


    }











    // THIS IS WHY IT DIDN'T WORK ON OLD IOS DEVICES!!
    //Detect a page refresh
    //Need to add a function to prevent going to the log in page (NOT SURE WHAT THOUGH?) Elle
    //if (window.performance.getEntriesByType) {
        //if (window.performance.getEntriesByType("navigation")[0].type === "reload") {
            //console.log("this is a reload");
            //console.log("commission bonuses: ", commission_bonuses);
       //}
     //}





    /* ------------------------------------------ */
    /* ---- Handle browser back button click ---- */
    /* ------------------------------------------ */

    //check when the user clicks the browser back button - send them to the dashboard or log in page
    useEffect(() => {

        //handle the back button click
        const handle_back_click = (token, set_token, set_active_panel) => {

            //get the log in cookie token value
            let cookie_token = get_cookie("log_in_cookie");

            //get the active panel cookie value
            let cookie_active_panel = get_cookie("active_panel_cookie");

            if (cookie_token !== "" && cookie_token !== undefined) {
                                    
                //we have a token

                if (cookie_active_panel === "Dashboard") { 

                    //they're on the dashboard

                    //display confrimation dialog - do they want to log out?
                    display_confirmation_dialog(
                        "Would you like to log out of the portal?",
                        "Yes",
                        "No",
                        function (is_confirmed) {

                            if (is_confirmed) {

                                //yes, so log them out
                                log_out(token, set_token, set_active_panel);

                                //and reset the data
                                reset_data();

                            }

                        });        

                } else {

                    //we have a token but they're not on the dashboard

                    //send them to the dashboard (NEEDS UPDATING - MOVE EMPTY STUFF INTO APP re SCROLL and SUB PAGES --- ELLE)
                    SetActivePanelButton(
                        "Dashboard",
                        set_active_panel,
                        "",
                        "",
                        "",
                        toggle_sidebar,
                        set_toggle_sidebar,
                        device_type)

                }

            } else {

                //we don't have a token so we must be on the log in page

                //let them go back
                window.history.back();

                return;

            }

        };

        //add a new state to the browser's session history to manipulate the browser's history before adding an event listener for the popstate event
        //by setting document.URL, it prevents the default behavior of the back button. When the user clicks the back button, it triggers the popstate event with the modified state, and we can handle that event to perform handle_back_click function
        window.history.pushState(null, null, document.URL);

        //event listener for the popstate event - when the user clicks the back or forward button
        //run handle_back_click on popstate event
        window.addEventListener('popstate', function (event) {

            //console.log("they clicked back!");

            handle_back_click(token, set_token, set_active_panel);

        });

        //cleanup: remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handle_back_click);
        };

    }, []); //empty dependency array means this effect runs once


    /* ------------------------------------------ */
    /* ------------------------------------------ */




    /* ------------------------------------------ */
    //display pop up dialog if they are new to the portal
    useEffect(() => {

        //if they are new to the portal
        if (first_log_in) {

            //it's a new user

            //display the dialog with one a single button that provides a callback response
            display_confirmation_dialog_single_button_with_response(

                "Hello!<br /><br/>It looks like you're new here, welcome to StrichSuite.<br />This portal is very much a 'work in progress' and if yuo dont liek it then yuo can <b>go rent a shoe</b> becuase I dont care!!1",
                "OK",
                function (is_confirmed) {

                    if (is_confirmed) {

                        //say they've seen it
                        set_first_log_in(false);

                    }

                })

        } else {

            //it's not a new user

        }

    }, [first_log_in]); // add first_log_in as a dependancy

    /* ------------------------------------------ */
    /* ------------------------------------------ */



    //------------------------------------------------------ //
    // CHECK THE USERS ACTIVITY AND LOG THEM OUT IF INACTIVE //
    // NB: this does not monitor the customizr screen activity - only the portal

    //set variable so we can generate the current timestamp
    const getCurrentTime = () => new Date().getTime();


    //set a state for the last active timestamp
    const [is_active_timestamp, set_is_active_timestamp] = useState(getCurrentTime);

    //handle what happens when the user's mouse generates an event
    const handle_activity = () => {

            //set variable so we can generate the current timestamp
            const getCurrentTime = () => new Date().getTime();

            //set the is_active time to the current time
            set_is_active_timestamp(getCurrentTime());

            //console.log("user activity time: ", getCurrentTime());

    };

    //useEffect loop to run every 10 seconds - checks the time difference between the current time and the last active timestamp
    useEffect(() => {

        //run every 10 seconds
        const interval = setInterval(() => {

            //get the current time
            const current_time = getCurrentTime();

            //check the difference between the current time and the set_active_timestamp
            const time_difference = current_time - is_active_timestamp;

            //console.log("token = ", token);
            //console.log(`Time difference: ${time_difference} milliseconds`);

            //set our time limit for when we want to kick inactive users out
            const inactivity_time_limit = 3600000;

            //if the time difference is greater than our inactivity_time_limit
            if (time_difference > inactivity_time_limit) {

                //they're inactive - log them out
                log_out(token, set_token, set_active_panel)

                //clear the interval
                clearInterval(interval);
            }
                        
        }, 10000); // 10000 (milliseconds) for a 10-second interval

        //add event listener to mouse click and keyboard click - can monitor other thigns if we want like 'mouseover'
        window.addEventListener('click', handle_activity);
        window.addEventListener('keydown', handle_activity);

        return () => {

            //clear the event listeners and Interval
            window.removeEventListener('click', handle_activity);
            window.removeEventListener('keydown', handle_activity);
            clearInterval(interval);

        };

    }, [is_active_timestamp]); //useEffect dependancies monitor is_active_timestamp

    //-----------------------------------------
    //-----------------------------------------














    //give desktop or mobile view depending on screen size
    return (

    <div className="portal">
                      

            {BuildPage()}
                      

    </div>

    )
};



export default App;