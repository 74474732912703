import React, { useState } from 'react';

import './css/login.css';

import {
    auth_call,
    api_router_reset_password,
    api_router_update_password,
    api_router_create_account,
    api_router_activate_account,
    api_router_update_email,
    api_router_get_designer_name
} from './api_core_calls';

import {
    get_cookie,
    set_cookie
} from './functions_cookies';

import {
    display_confirmation_dialog,
    display_confirmation_dialog_single_button,
    display_confirmation_dialog_single_button_with_response,
} from './functions_dialog';




//display log out diaplog when user tries to log out
export function user_log_out_dialog(token, set_token, set_active_panel) {

    // Display confrimation dialog
    display_confirmation_dialog(
        "Are you sure you want to log out of the portal?",
        "Yes",
        "No",
        function (is_confirmed) {

            if (is_confirmed) {

                // Make the call
                log_out(token, set_token, set_active_panel);

            }

        });

}



//log out
export function log_out(token, set_token, set_active_panel) {

    //clear the token
    set_token(null);

    //clear the active panel
    set_active_panel("");

    //clear the log in cookie
    set_cookie("log_in_cookie", "", "60");

    //set a cookie with the active panel as 'Log In'
    set_cookie("active_panel_cookie", "Log In", "1");

    //console.log("logout, token should be null: ", token);

};




//log in
export function login(set_token, set_has_to_do_list_dialog_been_seen, set_first_log_in) {

    return (

        <Login set_token={set_token} set_has_to_do_list_dialog_been_seen={set_has_to_do_list_dialog_been_seen} set_first_log_in={set_first_log_in} />
        
        )

}



const Login = ({ set_token, set_has_to_do_list_dialog_been_seen, set_first_log_in }) => {

    //get url params //

    const url_parameters = new URLSearchParams(window.location.search);

    const referrer = url_parameters.get('atid');

    // ----------- //



    // handle button clicks //
        
    const login_button = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Get the username and password from the forms info
        var { username, password } = document.forms[0];

        // Make the authorisation call and set the token if true or show an error alert if false
        auth_call(username.value, password.value).then(response => {

            if (response["success"]) {

                //set the token
                set_token(response["token"]);

                //check to see if this is a new user
                if (response["last_log_in"] === null) {

                    //they've never logged in before so they're new to the portal - set first_log_in state to true
                    set_first_log_in(true);

                } else {

                    //they've been here before so set first_log_in state to false
                    set_first_log_in(false);

                };

                //set a cookie with the token
                set_cookie("log_in_cookie", response["token"], "60");

                //set a cookie with the Dashboard as the active panel
                set_cookie("active_panel_cookie", "Dashboard", "60");

                //set the satate of the to do list dialog
                set_has_to_do_list_dialog_been_seen("N");


            } else {

                //alert error message
                display_confirmation_dialog_single_button(response["error_message"], "OK");

                //alert(response["error_message"]);

            }

        })

    }

    const submit_new_password_button = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Get the username and password from the forms info
        var { new_password, confirm_password } = document.forms[0]

        // Check for a password
        if (!new_password.value) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Password is missing.", "OK");
            return

        }

        // Check if the passwords match
        if (!(new_password.value === confirm_password.value)) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Passwords don't match.", "OK");
            return

        }

        // The passwords match, so on we go

        // Make the call
        api_router_update_password(url_parameters.get('email'), new_password.value, url_parameters.get('token')).then(response => {

            if (response["success"]) {

                // Let 'em know
                alert("Password changed")

                // Reload the page (without the parameters)
                window.location.href = "/"

            } else {
                alert(response["error_message"])
            }

        })

    }

    const submit_new_email_address_button = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Get the email and confirmation from the forms info
        var { new_email, confirm_new_email } = document.forms[0]

        // Check for a password
        if (!new_email.value) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Email address is missing.", "OK");
            return

        }

        // Check if the emails match
        if (!(new_email.value === confirm_new_email.value)) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Email addresses don't match.", "OK");
            return

        }

        // They match, so on we go

        // Make the call
        api_router_update_email(url_parameters.get('email'), new_email.value, url_parameters.get('token')).then(response => {

            if (response["success"]) {

                // Let 'em know
                alert("Email address changed. Please log in with your new email.")

                // Reload the page (without the parameters)
                window.location.href = "/"

            } else {
                alert(response["error_message"])
            }

        })

    }

    const sign_up_button = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Get the username and password from the forms info
        var { username, new_password, confirm_password } = document.forms[0]

        // Check for an email address
        if (!username.value) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Email address is missing.", "OK");
            return

        }

        // Check for a password
        if (!new_password.value) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Password is missing.", "OK");
            return

        }

        // Check if the passwords match
        if (!(new_password.value === confirm_password.value)) {

            // They don't, so bug out
            display_confirmation_dialog_single_button("Passwords don't match.", "OK");
            return

        }


        // The passwords match, so on we go

        // Make the call
        api_router_create_account(username.value, new_password.value, get_cookie("referrer")).then(response => {

            if (response["success"]) {

                // Let 'em know   
                //display the dialog with one a single button that provides a callback response
                display_confirmation_dialog_single_button_with_response(

                    "Done. Check your emails to verify your account, then log in.",
                    "OK",
                    function (is_confirmed) {

                        if (is_confirmed) {

                           // Reload the page (without the parameters)
                           window.location.href = "/"

                        }

                    })

            } else {

                alert(response["error_message"])

            }

        })

    }

    const reset_password = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Get the username from the forms info
        var { username } = document.forms[0];

        if (!username.value) {
            display_confirmation_dialog_single_button("No email address provided.", "OK");
            return
        }

        // Make the call to reset the password and show an error if failed
        api_router_reset_password(username.value).then(response => {

            if (response["success"]) {
                alert(response["results"])
            } else {
                alert(response["error_message"])
            }

        })
        return
    }

    const sign_up = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Reload the page (with the sign_up parameters)
        window.location.href = "/?action=sign_up"

        return
    }

    //go to login screen from sign up screen if they already have an account
    const log_in = (event) => {

        // Prevent page reload
        event.preventDefault();

        // Reload the page (with the login parameters)
        window.location.href = "/"

        return
    }

    // ------------------- //




    //activate account function //

    function activate_account() {

        // Make the call
        api_router_activate_account(url_parameters.get('email'), url_parameters.get('token')).then(response => {

            //console.log("fart")

            if (response["success"]) {

                //set the prefill email cookie to the url email from the sign up so we can auto add it to the log in form
                set_cookie("prefill_email", url_parameters.get('email'), 1);

                alert("Your account has been activated. Please log in.")

            } else {

                alert(response["error_message"])

            }

            // Reload the page (with the sign_up parameters)
            window.location.href = "/"

            return

        })

    };

    // ---------------------- //


    




    //forms

    function login_form() {

        let prefill_email = get_cookie("prefill_email");

        return (

            <div className="login_box_outer">

                <div className="login_form">

                    <form >

                        <div className="login_box">

                            <div className="welcome_message">
                                <h1>StrichSuite</h1>
                                <p>Please log in to view your account.</p>
                            </div>

                            <div className="login-text-box">
                                <input type="text" name="username" placeholder="Email" required defaultValue={prefill_email ? prefill_email : ""} />
                            </div>

                            <div className="login-text-box">
                                <input type="password" name="password" placeholder="Password" />
                            </div>
                            <div className="reset_password_button cursor_pointer" onClick={reset_password}>
                                Forgotten password?
                            </div>

                            <div className="button_area" >
                                <input id="login_button" className="login-button" type="submit" value="LOGIN" onClick={login_button} />

                            </div>

                        </div>

                        <div className="welcome_message">

                            <div className="align_contents_middle">
                                <hr className="horizontal_line_login_outer" />
                                <div>or</div>
                                <hr className="horizontal_line_login_outer" />
                            </div>

                            <h1>Create an account</h1>
                            <p>Unleash the Strich and make yourself rich!</p>

                            
                            <br />

                            <input id="sign_up" className="login_page_buttons" type="submit" value="SIGN UP" onClick={sign_up} />

                        </div>

                    </form>

                </div>

            </div>

        )
    };

    function login_reset_password_form() {

        return (

            <div className="login_box_outer">

                <div className="login_form">

                    <form >

                        <div className="login_box sign_up">

                            <div className="welcome_message">
                                <h1>UPDATE PASSWORD</h1><br />
                                <p>Please enter your new password</p>
                            </div>

                            <div className="login-text-box">
                                <input type="password" name="new_password" placeholder="Password" />
                            </div>

                            <div className="login-text-box">
                                <input type="password" name="confirm_password" placeholder="Confirm Password" />
                            </div>

                            <div>
                                <input id="login_button" className="login-button" type="submit" value="SUBMIT" onClick={submit_new_password_button} />
                            </div>

                            <div>
                                <label>By clicking 'submit', I agreed to the <a href="https://www.azeeda.com/azeeda-licence-agreement" target="blank">Azeeda licence agreement</a><br /> and <a href="https://www.azeeda.com/azeeda-commission-agreement" target="blank">Azeeda commission agreement</a>.</label>
                            </div>

                            <br />
                            <br />

                            <hr className="horizontal_line_grey" />

                            <br />
                            <br />

                            <div className="cursor_pointer" onClick={log_in}>
                                <b>Don't want to reset your password?</b>
                                <br/>
                                Click here to log in instead.
                            </div>



                        </div>

                    </form>

                </div>
            </div>

        )
    };

    function login_change_email_form() {

        return (

            <div className="login_box_outer">
                <div className="login_form">

                    <form >

                        <div className="login_box sign_up">

                            <div className="welcome_message">
                                <h1>CHANGE EMAIL ADDRESS</h1><br />
                                <p>Please enter your new email address</p>
                            </div>

                            <div className="login-text-box">
                                <input type="text" name="new_email" placeholder="Email address" />
                            </div>

                            <div className="login-text-box">
                                <input type="text" name="confirm_new_email" placeholder="Confirm email address" />
                            </div>

                            <div>
                                <input id="login_button" className="login-button" type="submit" value="SUBMIT" onClick={submit_new_email_address_button} />
                            </div>

                            <div>
                                <label>By clicking 'submit', I agreed to the <a href="https://www.azeeda.com/azeeda-licence-agreement" target="blank">Azeeda licence agreement</a><br /> and <a href="https://www.azeeda.com/azeeda-commission-agreement" target="blank">Azeeda commission agreement</a>.</label>
                            </div>

                            <br />
                            <br />

                            <hr className="horizontal_line_grey" />

                            <br />
                            <br />

                            <div className="cursor_pointer" onClick={log_in}>
                                <b>Don't want to change your email address?</b>
                                <br />
                                Click here to log in instead.
                            </div>

                        </div>

                    </form>
                </div>
            </div>

        )

    };


    const login_sign_up_form = () => {

        // Gets the html for the referral string
        const referrer_lookup = () => {
            var referrer_name = get_cookie("referrer_name");

            if (referrer_name) {
                return <div> Referred by: {referrer_name} </div>
            } else {
                return ""
            }
        };


        return (

            <div className="login_box_outer">

                <div className="login_form">

                    <form >

                        <div className="login_box sign_up">

                            <div className="welcome_message">
                                <h1>StrichSuite</h1>
                            </div>

                            <div className="login-text-box">
                                <input type="text" name="username" placeholder="Email Address" />
                            </div>

                            <div className="login-text-box">
                                <input type="password" name="new_password" placeholder="Password" />
                            </div>

                            <div className="login-text-box">
                                <input type="password" name="confirm_password" placeholder="Confirm Password" />
                            </div>

                            <br />

                            {referrer_lookup()}

                            <br />

                            <p>We'll send you an email to finish setting up your account.</p>

                            <div>
                                <input id="login_button" className="login-button" type="submit" value="SIGN UP" onClick={sign_up_button} />
                            </div>

                            <div className="cursor_pointer" onClick={log_in}>Already have an account? Click here.</div>
                            <br />

                            <br />

                        </div>

                    </form>

                </div>

            </div>

        )

    };


   
    // Catch the referral
    if (referrer) {

        // Get the referrer stored in the cookies (if any)
        var stored_referrer = get_cookie("referrer")

        // Check if we have a stored referrer - if we do, we don't want to overwrite it with a new one
        // The first person to refer them wins the prize!
        if (!stored_referrer) {

            // There isn't one stored so let's add the current one
            set_cookie("referrer", referrer, 30)

            // We also set the referrer name in a cookie because we can't look it up easily via API call later
            api_router_get_designer_name(referrer, true)

        }

    }



    return (
        <div className="login_page">

            <div className="login_page_contents">

                <div>

                    {(() => {

                        switch (url_parameters.get('action')) {
                            case "reset_password":
                                if (!url_parameters.get('token')) {
                                    return login_form(); //display default login screen when there's no token
                                }
                                return login_reset_password_form();
                            case "sign_up":
                                return login_sign_up_form();
                            case "activate_account":
                                //trigger the activate account function
                                activate_account();
                                //return a placeholder - used to be <div>Activating...</div>
                                return login_form();
                            case "update_email":
                                if (!url_parameters.get('token')) {
                                    return login_form(); //display default login screen when there's no token
                                }
                                return login_change_email_form();
                            default:
                                return login_form();
                        }
                    })()}

                    {LoginScreenInfo()}

                </div>

            </div>

            {login_screen_footer()}

            {login_screen_under_footer_details()}

        </div>

    )
};








//newer log in screen
function LoginScreenInfo() {

    //set a state for what info type to display to the user on the login page
    const [login_info_type, set_login_info_type] = useState(null);


    return (

        <div className="log_in_right_main_div">

                <div className="log_in_right_div_content main">
                    <br/><br/><br/><br/><br/>
                    <img src="https://strichsuite.com/images/strich.png" alt="Design" />

                    <div className="login_right_title">
                        <div className="login_right_title_header main">Unleash the Strich!</div>
                    </div>

                </div>

        </div>

    );
};






//login screen footer - with about us, recently sold and recent settlements
function login_screen_footer() {

    return (

        <div className="login_page_footer">

            <div className="login_footer_about_us">                        

            </div>

        </div>

    )
};

//login screen base footer details
function login_screen_under_footer_details() {

    return (

        <div className="login_under_footer_details">
            &#169; StrichSuite {new Date().getFullYear()} - A Pantheon Business Group Service
        </div>
        
        )
};

