import React from 'react';

//import { useState, useEffect } from "react";


import {
    panel_contact_tech_support
} from './panel_contact';

import {
    PanelAI
} from './panel_ai';

import {
    PanelAccount
} from './panel_account';

import {
    SetActivePanelButton
} from './pane_sidebar';

export function pane_main(
    token,
    refresh_flags,
    set_refresh_flag,
    changed_flags,
    set_changed_flag,
    active_panel,
    device_type,
    account_details,
    set_account_details,
    ai_queue,
    set_ai_queue,
    ai_to_process,
    set_ai_to_process,
    ai_styles,
    set_active_panel
) {


    return (

        <PaneMain
            token={token}

            refresh_flags={refresh_flags}
            set_refresh_flag={set_refresh_flag}

            changed_flags={changed_flags}
            set_changed_flag={set_changed_flag}

            active_panel={active_panel}
            device_type={device_type}

            account_details={account_details}
            set_account_details={set_account_details}

            ai_queue={ai_queue}
            set_ai_queue={set_ai_queue}

            ai_to_process={ai_to_process}
            set_ai_to_process={set_ai_to_process}

            ai_styles={ai_styles}

            set_active_panel={set_active_panel}

        />

    )
}

const PaneMain = ({
    token,
    refresh_flags,
    set_refresh_flag,
    changed_flags,
    set_changed_flag,
    active_panel,
    device_type,
    account_details,
    set_account_details,
    ai_queue,
    set_ai_queue,
    ai_to_process,
    set_ai_to_process,
    ai_styles,
    set_active_panel

}) => {

    function select_panel(
        token,
        refresh_flags,
        set_refresh_flag,
        changed_flags,
        set_changed_flag,
        active_panel,
        device_type,
        account_details,
        set_account_details,
        ai_queue,
        set_ai_queue,
        ai_to_process,
        set_ai_to_process,
        ai_styles,
        set_active_panel
    ) {

        switch (active_panel) {

            case "Contact":
                return (
                    <div>
                        {panel_contact_tech_support()}
                    </div>
                )

            case "Orders":
                return (
                    <div>

                        <h1> Orders panel. Nothing here yet.</h1>

                    </div>
                )

            case "AI":

                return (
                    <div>

                        {PanelAI(refresh_flags, set_refresh_flag, token, ai_queue, ai_to_process, ai_styles)}


                    </div>
                )

            case "Account Settings":
                return (
                    <div>

                        {PanelAccount(refresh_flags, set_refresh_flag, token, account_details, set_account_details)}

                    </div>
                )

            // The dashboard
            default:

                return (

                    <div>

                        <h1>Main panel</h1>
                        <div style={{ display: 'flex' }} >
                            <div style={{ height: '400px', width: '300px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img alt="AI" src="https://www.strichsuite.com/images/AI.png" style={{ height: '346px', width: '269px' }} onClick={() => SetActivePanelButton("AI", set_active_panel)} />
                                </div >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h1>AI</h1>
                                </div >
                            </div>

                        </div>



                    </div>
                )
        }
    }






    return (
        <div className="pane_main" id={active_panel.replace(/\s+/g, '_')}>
            {select_panel(
                token,
                refresh_flags,
                set_refresh_flag,
                changed_flags,
                set_changed_flag,
                active_panel,
                device_type,
                account_details,
                set_account_details,
                ai_queue,
                set_ai_queue,
                ai_to_process,
                set_ai_to_process,
                ai_styles,
                set_active_panel
            )}
        </div>

    )

};

