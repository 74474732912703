import React, { useState, useRef, useEffect } from 'react';

import {
    get_queue,
    add_to_queue,
    get_queue_item
}
    from './api_strich_calls';

import {
    display_confirmation_dialog,
    display_information_dialog,
    display_confirmation_dialog_single_button
}
    from './functions_dialog';



export function PanelAI(
    refresh_flags,
    set_refresh_flag,
    token,
    ai_queue,
    ai_to_process,
    ai_styles
) {

    return (

        <PanelAIDetail
            refresh_flags={refresh_flags}
            set_refresh_flag={set_refresh_flag}
            token={token}
            ai_queue={ai_queue}
            ai_to_process={ai_to_process}
            ai_styles={ai_styles}
        />

    )
}



//Profile user details
const PanelAIDetail = ({ refresh_flags, set_refresh_flag, token, ai_queue, ai_to_process, ai_styles }) => {

    // Timers for the refresh flags
    const [image_refresh_timer, set_image_refresh_timer] = useState(3);

    // Timer that counts down and sets the refresh flags to keep the dashboard up to date
    useEffect(() => {

        // Decrease the timer every second
        image_refresh_timer > 0 && setTimeout(() =>
            set_image_refresh_timer(image_refresh_timer - 1), 1000,
        );

        // If at 0, reset the timer and set the refresh flags
        if (image_refresh_timer === 0) {

            if (selected_queue_item) {

                if (!selected_queue_item.image_url) {

                    // Get the selected item again
                    get_queue_item(token, selected_queue_item.id, set_selected_queue_item)

                    // If we've still got no image, reset the timer
                    if (!selected_queue_item.image_url) {

                        // Reset the timer
                        set_image_refresh_timer(3)

                    }

                }

            }

        };


    }, [image_refresh_timer]);

    //set a boolean state for whether a design is in the process of being submitted (to stop designs being submitted multiple times)
    const [selected_queue_item, set_selected_queue_item] = useState(false);

    //create an array with the users original details
    let queue_item = [];

    //Save changes function - user details
    const submit_button = (event) => {

        // Prevent page reload
        event.preventDefault()

        // Populate the queue item from the form
        queue_item.prompt = document.getElementById('queue_prompt').value;

        // Add the style from the dropdown - will expand this to add multiple styles
        queue_item.styles = [];
        queue_item.styles.push(document.getElementById("style_dropdown").value)


        // Make the call 
        add_to_queue(token, queue_item).then(response => {

            if (response["success"]) {

                // Set the selected item - really could do with this refreshing on a timer
                get_queue_item(token, response.results.id, set_selected_queue_item)

                // Set the image refresh timer
                set_image_refresh_timer(3)

                // Let 'em know
                display_confirmation_dialog_single_button("Added to queue.", "OK");

            } else {
                display_confirmation_dialog_single_button(response["error_message"], "OK");
            }

        })

    };

    const information_header = "AI Wrangling";
    const information_message = "Use the power of Strich to make ridiculous images.";

    return (

        <div>

            <div ><br /><br />If processing doesn't start then either someone's in the queue before you or the rig is turned off.</div>
            <div>Check the queue numbers to see if there's a lot processing or the numbers aren't going down.</div>
            <div>If the rig is running, you'd expect to see the count drop by one every 30-40 seconds.</div>

            <div id="ai_image_scroll" />
            <div id="profile_information_panel" className="panels_with_header"  >

                <div id="ai_image" className="panel_header_bar panel_header_background_turquoise"><p>Selected Queue Item</p></div>

                <div id="ai_queue_submissions_details" className="panel_information_icon white"
                    onClick={() => display_information_dialog(information_header, information_message)}>
                </div>

                <div id="selected_queue_item">

                    <div id="user_profile_details" className="user_profile_details">

                        <form>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <DisplayImage selected_queue_item={selected_queue_item} />
                            </div>
                        </form>

                    </div>

                </div>

            </div>

            <div id="profile_information_panel" className="panels_with_header" >

                <div id="ai_queue_submissions" className="panel_header_bar panel_header_background_turquoise"><p>Queue Status</p></div>

                <div id="user_info_content">

                    <div id="user_profile_details" className="user_profile_details">

                        <div className="button_area" >
                            <h1>Current queue count: {ai_to_process.length}</h1>
                        </div>

                    </div>

                </div>

            </div>

            <div id="profile_information_panel" className="panels_with_header" >

                <div id="ai_queue_submissions" className="panel_header_bar panel_header_background_turquoise"><p>Submit Text To Image Job</p></div>

                <div id="ai_queue_submissions_details" className="panel_information_icon white"
                    onClick={() => display_information_dialog(information_header, information_message)}>
                </div>

                <div id="user_info_content">

                    <div id="user_profile_details" className="user_profile_details">

                        <form>
                            <br />
                            <label htmlFor="prompt" className="form_title info_title">Prompt</label>
                            <input placeholder="What do you want to create?" className="form_input" type="text" id="queue_prompt" name="queue_prompt" />
                            <br />
                            <br />

                            <div className="button_area" >
                                Available styles
                                <Styles ai_styles={ai_styles} />
                            </div>
                            <br />


                            <div className="button_area" >
                                <input id="submit_button" className="login-button" type="submit" value="SUBMIT" onClick={submit_button} />
                            </div>
                        </form>

                    </div>

                </div>

            </div>

            <div id="profile_information_panel" className="panels_with_header" style={{ height: '100%', width: '900px' }}>

                <div id="profile_details" className="panel_header_bar panel_header_background_turquoise"><p>Queue - click line item to display above</p></div>

                <div id="profile_details_information" className="panel_information_icon white"
                    onClick={() => display_information_dialog(information_header, information_message)}>
                </div>

                <div id="user_info_content">

                    <div id="user_profile_details" className="user_profile_details">

                        <div id="queue" >
                            <Queue token={token} ai_queue={ai_queue} set_selected_queue_item={set_selected_queue_item} />
                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

const DisplayImage = ({ selected_queue_item }) => {

    if (!selected_queue_item) {

        return (
            <h1>No queue item selected. Click one in the list below.</h1>
        )
    }

    if (selected_queue_item.image_url) {

        return (
            <img alt={selected_queue_item.prompt} src={selected_queue_item.image_url} style={{ height: '461px', width: '359px' }} />
        )

    } else {

        return (
            <h1>Image not ready yet. Be patient, grasshopper.</h1>
        )

    }
}

const Styles = ({ ai_styles }) => {


    return (

        <div id="dropdown_styles">
            <select id="style_dropdown">

                {ai_styles.map((style, index) => (
                    <option value={style} key={index}>
                        {style}
                    </option>
                ))}

            </select>
        </div>
    )
}


const Queue = ({ token, ai_queue, set_selected_queue_item }) => {

    //create refs so the leaderboards will scroll to active user on mount
    const queue_list_containerRef = useRef(null);
    const queue_list_itemRef = useRef(null);

    // Get the current queue item
    function set_selected(item) {
        get_queue_item(token, item.id, set_selected_queue_item)
        document.getElementById("ai_image_scroll").scrollIntoView()
    }

    //create the list data
    function format_name(queue_item) {

        // Return the line normally
        return (
            <div ref={queue_list_itemRef} className={queue_item.id + " queue_item"} >

                <div className="queue_list_row" onClick={() => set_selected(queue_item)}>

                    <span className="queue_list_names" style={{ padding: '5px', overflow: 'hidden', display: 'inline-block', width: '300px' }}>{queue_item.prompt}</span>
                    <span className="queue_list_names" style={{ padding: '5px', overflow: 'hidden', display: 'inline-block', width: '40px' }}>{queue_item.progress}%</span>
                    <span className="queue_list_names" style={{ padding: '5px', overflow: 'hidden', display: 'inline-block', width: '80px' }}>{queue_item.run_by}</span>
                    <span className="queue_list_names" style={{ padding: '5px', overflow: 'hidden', display: 'inline-block', width: '200px' }}>{queue_item.styles}</span>
                    <span className="queue_list_names" style={{ padding: '5px', overflow: 'hidden', display: 'inline-block', width: '140px' }}>{queue_item.created}</span>
                    <span className="queue_list_names" style={{ padding: '5px', overflow: 'hidden', display: 'inline-block', width: '140px' }}>{queue_item.completed}</span>
                </div>
            </div>
        )
    }

    //cycles through each item in the leaderboard and creates the line item for it
    const ai_queue_detail =

        //check if the data exists otherwise set to null (needed for page refresh)
        ai_queue !== undefined ?

            ai_queue.map((queue_item) => (
                <div key={queue_item.id}>
                    {format_name(queue_item)}
                </div>
            ))


            : null;


    // Return the panel with the AI queue data in it
    return (

        <div style={{ height: '100%', width: '900px' }}>

            <div id="container">
                <div ref={queue_list_containerRef} id="ai_queue_ref" className="list_container" style={{ overflow: 'auto', width: '900px' }}>
                    <div className="list">
                        {ai_queue_detail}
                    </div>
                </div>
            </div>


        </div>
    )

};