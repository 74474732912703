import React from 'react';

import { useState, useEffect, useRef } from "react";

import {
    pane_header
} from './pane_header';

import {
    pane_sidebar
} from './pane_sidebar';

import {
    pane_main
} from './pane_main';



import './css/panes.css';
import './css/panels.css';
import './css/tables.css';
import './css/buttons.css';
import './css/dialogbox.css';
import './css/designs_list.css';
import './css/dashboard.css';




export function handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type) {

    if (device_type === "desktop") {

        //set a short timeout so react has time to mount the new scrollable div content
        //otherwise it's equal to the previous height and only works if you double click the page button
        setTimeout(() => {

            const check_if_div_needs_scrolling = () => {
                if (!scrollable_bottom_arrow_divRef.current) {
                    return false;
                }
                //calculate whether the div needs to scroll using the ref div attributes
                const { scrollTop, clientHeight, scrollHeight } = scrollable_bottom_arrow_divRef.current;
                const pageBottom = scrollHeight - clientHeight;
                return scrollTop >= pageBottom;
            };

            const div_needs_scrolling = check_if_div_needs_scrolling();

            set_show_bottom_page_scroll_arrow(!div_needs_scrolling);


        }, 100)

    }

}





export function refresh_page (
    token, 
    set_token,
    refresh_flags,
    set_refresh_flag,
    changed_flags,
    set_changed_flag,
    active_panel,
    set_active_panel,
    toggle_sidebar,
    set_toggle_sidebar,
    device_type,
    set_device_type,
    account_details,
    set_account_details,
    ai_queue,
    set_ai_queue,
    ai_to_process,
    set_ai_to_process,
    ai_styles
) {

    return (

        <RefreshPage

            token={token}
            set_token={set_token}

            refresh_flags={refresh_flags}
            set_refresh_flag={set_refresh_flag}

            changed_flags={changed_flags}
            set_changed_flag={set_changed_flag}

            active_panel={active_panel}
            set_active_panel={set_active_panel}

            toggle_sidebar={toggle_sidebar}
            set_toggle_sidebar={set_toggle_sidebar}

            device_type={device_type}
            set_device_type={set_device_type}

            account_details={account_details}
            set_account_details={set_account_details}

            ai_queue={ai_queue}
            set_ai_queue={set_ai_queue}

            ai_to_process={ai_to_process}
            set_ai_to_process={ai_to_process}

            ai_styles={ai_styles}

        />

    )
}


const RefreshPage = ({
        token,
        set_token,
        refresh_flags,
        set_refresh_flag,
        changed_flags,
        set_changed_flag,
        active_panel,
        set_active_panel,
        toggle_sidebar,
        set_toggle_sidebar,
        device_type,
        set_device_type,
        account_details,
    set_account_details,
    ai_queue,
    set_ai_queue,
    ai_to_process,
    set_ai_to_process,
    ai_styles

    }) => {

    
    //set state for showing sub pages
    const [show_sub_pages, set_show_sub_pages] = useState(null);

    //set a state for showing/hiding the scroll button arrow
    const [show_bottom_page_scroll_arrow, set_show_bottom_page_scroll_arrow] = useState(false);

    //set the reference for the scrollable div
    const scrollable_bottom_arrow_divRef = useRef(null);


    useEffect(() => {

        //set the div we want to check - if it needs to scroll
        const div = scrollable_bottom_arrow_divRef.current;

        if (div) {

            handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type);

            div.addEventListener('scroll', () => handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type));
            window.addEventListener('resize', () => handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type));

            return () => {
                div.removeEventListener('scroll', () => handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type));
                window.removeEventListener('resize', () => handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type));
            };
        }

    }, [scrollable_bottom_arrow_divRef]);



    const bottom_page_scroll_arrow = {
       opacity: show_bottom_page_scroll_arrow ? '1' : '0',
    };







    // Build the page
    return (

        <div>

            <div className="sidebar_and_panemain_div">

                {pane_sidebar(
                    set_active_panel,
                    scrollable_bottom_arrow_divRef,
                    set_show_bottom_page_scroll_arrow,
                    show_sub_pages,
                    set_show_sub_pages,
                    toggle_sidebar,
                    set_toggle_sidebar,
                    device_type,
                    set_device_type,
                    token,
                    set_token
            )}


                <div className="pane_wrapper" ref={scrollable_bottom_arrow_divRef}>

                {pane_main(
                    token,
                    refresh_flags,
                    set_refresh_flag,
                    changed_flags,
                    set_changed_flag,
                    active_panel,
                    device_type,
                    account_details,
                    set_account_details,
                    ai_queue,
                    set_ai_queue,
                    ai_to_process,
                    set_ai_to_process,
                    ai_styles,
                    set_active_panel
                )}


                {show_bottom_page_scroll_arrow && (
                    <div id="scroll_down_page_arrow" className="scroll_down_page_arrow" style={bottom_page_scroll_arrow} onClick={() => scrollable_bottom_arrow_divRef.current.scrollBy(0, 500)}> {/* Adjust the scroll amount as needed */}
                        {/* Add arrow icon or SVG */}
                        
                    </div>
                )}



            </div>

            </div>

            <div className="header_div">

                {pane_header(
                    token,
                    set_token,
                    set_active_panel,
                    scrollable_bottom_arrow_divRef,
                    set_show_bottom_page_scroll_arrow,
                    set_show_sub_pages,
                    refresh_flags, set_refresh_flag,
                    toggle_sidebar,
                    set_toggle_sidebar,
                    device_type,
                    set_device_type
                    )}

            </div>


        </div>
    )

};