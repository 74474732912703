
export function set_cookie(cookie_name, cookie_value, days) {

    // Make a new date
    const d = new Date();

    // Set the date to the current date + however many days specified
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));

    // Set the expires string with the new date
    let expires = "expires=" + d.toUTCString();

    // Add the cookie
    //document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/;SameSite=None; Secure";
    document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/;SameSite=Strict";

};


export function get_cookie(name) {

    // Get a cookie using regex to find the name and return the value
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);

    if (match) {

        return match[2]

    };

};



//old function by Ben
export function get_cookie2(name) {

    // Add an equals on the end
    name = name + "=";

    // Get the array of the cookies
    let cookie_array = document.cookie.split(';');

    // Cycle through
    for (let i = 0; i < cookie_array.length; i++) {

        // Get the current one
        let cookie = cookie_array[i];

        // Looks like's it's trimming off leading blank spaces - don't know why
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    // Return blank if nothing found
    return "";
}