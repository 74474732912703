import {
    set_cookie
} from './functions_cookies';

import {
    display_confirmation_dialog_single_button
}
    from './functions_dialog';

// Alter these to flick between live and dev - touch nothing else

let api_path = "php/"
//let api_path = "http://localhost:8000/php/"
//let api_path = "http://192.168.178.45:8000/php/"




export async function auth_call(username, password) {

    // Now make the call
    let result = await fetch(api_path + 'strich_auth.php', {
        headers: {
            'Content-Type': 'application/json',
            'username': username,
            'password': password
        }
    });

    let response = await result.json();
    return response;

}

export async function customizr_get_new_token(product_code, image_url, title_msg, preview_msg, finish_msg, closing_customizr_msg, closing_on_submit_customizr_msg, left_and_right_side_bars, image_tab, text_tab, advanced_tab, layers_tab, align_controls, help_screen_finish_button) {


    // Create the data for the token call
    let api_call = {};

    api_call["product_code"] = product_code;
    api_call["image_url"] = image_url;

    api_call["messages"] = {
        "title": title_msg,
        "preview": preview_msg,
        "finish": finish_msg,
        "closing_customizr": closing_customizr_msg,
        "closing_on_submit_customizr": closing_on_submit_customizr_msg,
        "help_screen_finish_button": help_screen_finish_button,

        "use_desktop": "For the best experience we recommend using a desktop when adding designs!",

    };

    api_call["ui"] = {
        "left_and_right_side_bars": left_and_right_side_bars,
        "image_tab": image_tab,
        "text_tab": text_tab,
        "advanced_tab": advanced_tab,
        "layers_tab": layers_tab,

        "align_controls": align_controls,

        "email_me_div_on_preview_screen": "hide",
        "email_the_save_id_button": "hide",
        "rotate_product_button": "hide",

        "templates_tab": "hide",

        //"show_true_false": false,
    };

    api_call["brand"] = "Azeeda";

    api_call["logo_url"] = "https://designers.azeeda.com/images/azeeda_logo_circle.png";
    

    // Encode that
    let postdata = JSON.stringify(Object.assign({}, api_call));

    // Make the fetch call using the Strich customizr auth
    let result = await fetch(api_path + 'customizr_auth.php', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: postdata
    });

    let response = await result.json();
    return response;

} 


export async function api_router_reset_password(username) {

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=reset_password&email=' + username, {
        headers: {
            'Content-Type': 'application/json',
        }
    });

    let response = await result.json();
    return response

}

export async function api_router_update_password(username, new_password, token) {

    // Set the postdata 
    let update_data = [];
    update_data["email"] = username
    update_data["new_password"] = new_password
    update_data["token"] = token

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, update_data))

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=update_password', {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
        },
        body: postdata
    });

    let response = await result.json()
    return response

}

export async function api_router_update_email(username, new_email, token) {

    // Set the postdata 
    let update_data = [];
    update_data["email"] = username
    update_data["new_email"] = new_email
    update_data["token"] = token

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, update_data))

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=update_email', {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
        },
        body: postdata
    });

    let response = await result.json()
    return response

}

export async function api_router_create_account(username, new_password, referred_by) {

    // Set the postdata 
    let update_data = []
    update_data["email"] = username
    update_data["password"] = new_password
    update_data["referred_by"] = referred_by

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, update_data))

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=create_account', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: postdata
    });

    let response = await result.json()
    return response

}

export async function api_router_update_designer(designer) {

    // Convert to JSON
    let postdata = JSON.stringify(Object.assign({}, designer))

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=update_designer', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: postdata
    });

    let response = await result.json()
    return response

}

export async function api_router_activate_account(username, token) {

    let postdata = ""

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=activate_account&email=' + username + "&token=" + token, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: postdata
    });

    let response = await result.json();
    return response

}






export async function strich_call(method, endpoint, token, postdata, set_token) {

    //console.log(postdata);

    // Now make the call
    let result = await fetch('https://api.strichsuite.com/test/'+ endpoint, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
             'X-Strich-Auth-Token': token
        },
        body: postdata
    })
    //catch for errors and console.log them with the function name so we know where it's coming from
    .catch ((error) => {
        console.error("portal_call - Error in: ", endpoint, " Error message: ",  error);
    });


    //get the json
    let response = await result.json()




    //check if the portal or API is offline/online

    let offline_alert_shown = false;

    //if an api call fails with an offline message then give an alert to the user while it waits for the maintenance page to appear
    if (endpoint !== "status" && (response.error_message === "Portal Offline" || response.error_message === "API Offline")) {

        if (!offline_alert_shown) {
            alert("The portal appears to be offline, we're sorry for any inconvenience caused. Please check back later.");
            //display_confirmation_dialog_single_button("The portal appears to be offline, we're sorry for any inconvenience caused. Please check back later.", "OK");
            offline_alert_shown = true; //set the flag to true to indicate that the alert has been shown
        }
    };

    //we only want to monitor the 'status' call (not all api calls!) - NB: because it's the only call that passes in the set_token function
    if (endpoint === "status" && (response.error_message === "Portal Offline" || response.error_message === "API Offline")) {

        //it's offline so clear the token and replace it with token='offline'
        set_token("offline");

    //we only want to monitor the 'status' call (not all api calls!)
    } else if (endpoint === "status") {

        //it's online

        //check if the current token is 'offline'
        if (token === "offline") {

            //if so set it to false (for the log in page)
            set_token(false)

        } else {

            //it's not so set it to the token for that user's session
            set_token(token);

        }

        offline_alert_shown = true; //set the flag to true to indicate that the alert has been shown

    };


    return response;

}








//let result = await fetch('https://api.strichsuite.com/v1/customizr/get_token_status?token=' + token, {


export async function customizr_get_token_status(token) {

    //console.log("checking token");

    // Now make the call
    
    let result = await fetch('https://api.strichsuite.com/v1/customizr/token?token=' + token, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    });

    let response = await result.json()
    return response

}

export async function api_router_get_designer_name(referrer, set_cookie_value) {

    // Now make the call
    let result = await fetch(api_path + 'api_router.php/?action=get_designer_name&referrer=' + referrer, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    })

    // Get the JSON from the result
    let response = await result.json()

    // Check if we're supposed to be setting the cookie for this
    if (set_cookie_value) {

        set_cookie("referrer_name", response["results"], 30)

    }

    // Get the name from the JSON
    return(response["results"])

}