
export function display_confirmation_dialog(message, confirm_button_message, cancel_button_message, callback) {
    var is_confirmed = false;

    //create a div for the dialog (full screen)
    var confirmation_dialog = document.createElement("div");
    confirmation_dialog.classList.add("confirmation_dialog");

    //create a div for the dialog content
    var confirmation_dialog_content = document.createElement("div");
    confirmation_dialog_content.classList.add("confirmation_dialog_content");

    //create a paragraph of the dialog message
    var confirmation_dialog_message = document.createElement("p");
    confirmation_dialog_message.innerHTML = message;
    confirmation_dialog_message.classList.add("confirmation_dialog_message");

    //create a div for the buttons
    var confirmation_dialog_buttons_div = document.createElement("div");
    confirmation_dialog_buttons_div.classList.add("confirmation_dialog_buttons_div");

    //create a confirm button with confirm message
    var confirmation_dialog_button = document.createElement("button");
    confirmation_dialog_button.innerHTML = confirm_button_message;
    confirmation_dialog_button.classList.add("confirmation_dialog_button");

    //create a cancel button with cancel message
    var confirmation_dialog_cancel_button = document.createElement("button");
    confirmation_dialog_cancel_button.innerHTML = cancel_button_message;
    confirmation_dialog_cancel_button.classList.add("confirmation_dialog_button");

    //append them all together
    confirmation_dialog_content.appendChild(confirmation_dialog_message);
    confirmation_dialog_content.appendChild(confirmation_dialog_buttons_div);
    confirmation_dialog_buttons_div.appendChild(confirmation_dialog_button);
    confirmation_dialog_buttons_div.appendChild(confirmation_dialog_cancel_button);
    confirmation_dialog.appendChild(confirmation_dialog_content);
    document.body.appendChild(confirmation_dialog);

    //return true if confirm button is clicked
    confirmation_dialog_button.addEventListener("click", function () {
        is_confirmed = true;
        document.body.removeChild(confirmation_dialog);
        callback(is_confirmed);
    });

    //return false if cancel button is clicked and close dialog
    confirmation_dialog_cancel_button.addEventListener("click", function () {
        is_confirmed = false;
        document.body.removeChild(confirmation_dialog);
        callback(is_confirmed);
    });
}


export function display_confirmation_dialog_single_button(message, confirm_button_message) {

    //create a div for the dialog (full screen)
    var confirmation_dialog = document.createElement("div");
    confirmation_dialog.classList.add("confirmation_dialog");

    //create a div for the dialog content
    var confirmation_dialog_content = document.createElement("div");
    confirmation_dialog_content.classList.add("confirmation_dialog_content");

    //create a paragraph of the dialog message
    var confirmation_dialog_message = document.createElement("p");
    confirmation_dialog_message.innerHTML = message;
    confirmation_dialog_message.classList.add("confirmation_dialog_message");

    //create a div for the buttons
    var confirmation_dialog_buttons_div = document.createElement("div");
    confirmation_dialog_buttons_div.classList.add("confirmation_dialog_buttons_div");

    //create a confirm button with confirm message
    var confirmation_dialog_button = document.createElement("button");
    confirmation_dialog_button.innerHTML = confirm_button_message;
    confirmation_dialog_button.classList.add("confirmation_dialog_button");

    //append them all together
    confirmation_dialog_content.appendChild(confirmation_dialog_message);
    confirmation_dialog_content.appendChild(confirmation_dialog_buttons_div);
    confirmation_dialog_buttons_div.appendChild(confirmation_dialog_button);
    confirmation_dialog.appendChild(confirmation_dialog_content);
    document.body.appendChild(confirmation_dialog);

    //return true if confirm button is clicked
    confirmation_dialog_button.addEventListener("click", function () {
        document.body.removeChild(confirmation_dialog);
    });
}


export function display_confirmation_dialog_single_button_with_response(message, confirm_button_message, callback) {
    var is_confirmed = false;

    //create a div for the dialog (full screen)
    var confirmation_dialog = document.createElement("div");
    confirmation_dialog.classList.add("confirmation_dialog");

    //create a div for the dialog content
    var confirmation_dialog_content = document.createElement("div");
    confirmation_dialog_content.classList.add("confirmation_dialog_content");

    //create a paragraph of the dialog message
    var confirmation_dialog_message = document.createElement("p");
    confirmation_dialog_message.innerHTML = message;
    confirmation_dialog_message.classList.add("confirmation_dialog_message");

    //create a div for the buttons
    var confirmation_dialog_buttons_div = document.createElement("div");
    confirmation_dialog_buttons_div.classList.add("confirmation_dialog_buttons_div");

    //create a confirm button with confirm message
    var confirmation_dialog_button = document.createElement("button");
    confirmation_dialog_button.innerHTML = confirm_button_message;
    confirmation_dialog_button.classList.add("confirmation_dialog_button");

    //append them all together
    confirmation_dialog_content.appendChild(confirmation_dialog_message);
    confirmation_dialog_content.appendChild(confirmation_dialog_buttons_div);
    confirmation_dialog_buttons_div.appendChild(confirmation_dialog_button);
    confirmation_dialog.appendChild(confirmation_dialog_content);
    document.body.appendChild(confirmation_dialog);

    //return true if confirm button is clicked
    confirmation_dialog_button.addEventListener("click", function () {
        is_confirmed = true;
        document.body.removeChild(confirmation_dialog);
        callback(is_confirmed);
    });
}


export function display_confirmation_dialog_single_button_with_response_and_exit(message, confirm_button_message, callback) {
    var is_confirmed = false;

    //create a div for the dialog (full screen)
    var confirmation_dialog = document.createElement("div");
    confirmation_dialog.classList.add("confirmation_dialog");

    //create a div for the dialog content
    var confirmation_dialog_content = document.createElement("div");
    confirmation_dialog_content.classList.add("confirmation_dialog_content");

    //create a paragraph of the dialog message
    var confirmation_dialog_message = document.createElement("p");
    confirmation_dialog_message.innerHTML = message;
    confirmation_dialog_message.classList.add("confirmation_dialog_message");

    //create a div for the buttons
    var confirmation_dialog_buttons_div = document.createElement("div");
    confirmation_dialog_buttons_div.classList.add("confirmation_dialog_buttons_div");

    //create a confirm button with confirm message
    var confirmation_dialog_button = document.createElement("button");
    confirmation_dialog_button.innerHTML = confirm_button_message;
    confirmation_dialog_button.classList.add("confirmation_dialog_button");

    //create an exit button
    var confirmation_dialog_exit_button = document.createElement("img");
    confirmation_dialog_exit_button.src = "https://designers.azeeda.com/images/close_button.png";
    confirmation_dialog_exit_button.classList.add("information_dialog_exit_button");

    //append them all together
    confirmation_dialog_content.appendChild(confirmation_dialog_message);
    confirmation_dialog_content.appendChild(confirmation_dialog_exit_button);
    confirmation_dialog_content.appendChild(confirmation_dialog_buttons_div);
    confirmation_dialog_buttons_div.appendChild(confirmation_dialog_button);
    confirmation_dialog.appendChild(confirmation_dialog_content);
    document.body.appendChild(confirmation_dialog);

    //return true if confirm button is clicked
    confirmation_dialog_button.addEventListener("click", function () {
        is_confirmed = true;
        document.body.removeChild(confirmation_dialog);
        callback(is_confirmed);
    });

    //close dialog
    confirmation_dialog_exit_button.addEventListener("click", function () {
        document.body.removeChild(confirmation_dialog);
    });
}


export function display_information_dialog(information_header, information_message) {

    //create a div for the dialog (full screen)
    var information_dialog = document.createElement("div");
    information_dialog.classList.add("confirmation_dialog", "information");

    //create a div for the dialog content
    var information_dialog_content = document.createElement("div");
    information_dialog_content.classList.add("confirmation_dialog_content", "information_content");

    //create a header of the dialog
    var information_dialog_header = document.createElement("p");
    information_dialog_header.innerHTML = information_header;
    information_dialog_header.classList.add("information_dialog_header");

    //create a paragraph of the dialog message
    var information_dialog_message = document.createElement("p");
    information_dialog_message.innerHTML = information_message;
    information_dialog_message.classList.add("information_dialog_message");

    //create an exit button
    var information_dialog_exit_button = document.createElement("img");
    information_dialog_exit_button.src = "https://designers.azeeda.com/images/close_button.png";
    information_dialog_exit_button.classList.add("information_dialog_exit_button");

    //append them all together
    information_dialog_content.appendChild(information_dialog_header);
    information_dialog_content.appendChild(information_dialog_message);
    information_dialog_content.appendChild(information_dialog_exit_button);
    information_dialog.appendChild(information_dialog_content);
    document.body.appendChild(information_dialog);

    //close dialog
    information_dialog_exit_button.addEventListener("click", function () {
        document.body.removeChild(information_dialog);
    });
}


export function display_tutorial_video_dialog(tutorial_video_header, tutorial_video_message) {

    //create a div for the dialog (full screen)
    var information_dialog = document.createElement("div");
    information_dialog.classList.add("confirmation_dialog", "information");

    //create a div for the dialog content
    var information_dialog_content = document.createElement("div");
    information_dialog_content.classList.add("confirmation_dialog_content", "tutorial_video_content");

    //create a header of the dialog
    var information_dialog_header = document.createElement("p");
    information_dialog_header.innerHTML = tutorial_video_header;
    information_dialog_header.classList.add("information_dialog_header");

    //create a paragraph of the dialog message
    var information_dialog_message = document.createElement("p");
    information_dialog_message.innerHTML = tutorial_video_message;
    information_dialog_message.classList.add("tutorial_video_dialog_message");

    //create an exit button
    var information_dialog_exit_button = document.createElement("img");
    information_dialog_exit_button.src = "https://designers.azeeda.com/images/close_button.png";
    information_dialog_exit_button.classList.add("information_dialog_exit_button");

    //append them all together
    information_dialog_content.appendChild(information_dialog_header);
    information_dialog_content.appendChild(information_dialog_message);
    information_dialog_content.appendChild(information_dialog_exit_button);
    information_dialog.appendChild(information_dialog_content);
    document.body.appendChild(information_dialog);

    //close dialog
    information_dialog_exit_button.addEventListener("click", function () {
        document.body.removeChild(information_dialog);
    });
}


export function display_zoomed_images(zoom_image_header, image) {

    //create a div for the dialog (full screen)
    var information_dialog = document.createElement("div");
    information_dialog.classList.add("confirmation_dialog", "information");

    //create a div for the dialog content
    var information_dialog_content = document.createElement("div");
    information_dialog_content.classList.add("confirmation_dialog_content", "zoomed_image");

    //create a paragraph of the dialog message
    var information_dialog_message = document.createElement("img");
    information_dialog_message.src = image;
    information_dialog_message.classList.add("zoomed_image_in_dialog");

    //create an exit button
    var information_dialog_exit_button = document.createElement("img");
    information_dialog_exit_button.src = "https://designers.azeeda.com/images/close_button.png";
    information_dialog_exit_button.classList.add("information_dialog_exit_button");

    //append them all together
    information_dialog_content.appendChild(information_dialog_message);
    information_dialog_content.appendChild(information_dialog_exit_button);
    information_dialog.appendChild(information_dialog_content);
    document.body.appendChild(information_dialog);

    //close dialog
    information_dialog_exit_button.addEventListener("click", function () {
        document.body.removeChild(information_dialog);
    });
}



