import React, { useState, useRef, useEffect } from 'react';

import {
    display_confirmation_dialog,
    display_information_dialog,
    display_confirmation_dialog_single_button
}
    from './functions_dialog';

export function PanelAccount(refresh_flags, set_refresh_flag, token, account_details,set_account_details) {

    return (

        <PanelAccountDetail
            refresh_flags={refresh_flags}
            set_refresh_flag={set_refresh_flag}
            token={token}
            account_details={account_details}
            set_account_details={set_account_details}
        />

    )
}



//Profile user details
const PanelAccountDetail = ({ refresh_flags, set_refresh_flag, token, account_details, set_account_details }) => {

    //console.log(token)
    //console.log(account_details)

    const information_header = "Account Details";
    const information_message = "Just the details of your account.";

    if (account_details) {

        return (

            <div>

                <div id="profile_information_panel" className="panels_with_header" >

                    <div id="profile_details" className="panel_header_bar panel_header_background_turquoise"><p>Account Information</p></div>

                    <div id="profile_details_information" className="panel_information_icon white"
                        onClick={() => display_information_dialog(information_header, information_message)}>
                    </div>

                    <div id="user_info_content">

                        <div id="user_profile_details" className="user_profile_details">

                            <form>
                                <div>Email address: {account_details.email}</div>
                                <div>Registered date: {account_details.registered_date}</div>
                                <br />

                            </form>


                        </div>

                    </div>

                </div>

            </div>

        )
    }
}