
import './css/login.css';



export function maintenance() {

    return (

        <Maintenance />

    )

}


const Maintenance = () => {

    return (
        
        <div className="maintenance_page">

            <img className="maintenance_page_image" alt="We're undergoing maintenance." src="https://designers.azeeda.com/images/maintenance_page.png" />

        </div>
        
        
        )


}