import React, { useState, useRef, useEffect } from 'react';

import {
    get_profile_image
} from './user_details';

import {
    SetActivePanelButton
} from './pane_sidebar';

import {
    display_confirmation_dialog_single_button_with_response_and_exit,
}
    from './functions_dialog';

import {
    user_log_out_dialog
} from './login';







export function pane_header(token, set_token, set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, refresh_flags, set_refresh_flag, toggle_sidebar, set_toggle_sidebar, device_type, set_device_type) {

    return (

        <PaneHeader token={token} set_token={set_token} set_active_panel={set_active_panel} 
            scrollable_bottom_arrow_divRef={scrollable_bottom_arrow_divRef} set_show_bottom_page_scroll_arrow={set_show_bottom_page_scroll_arrow} set_show_sub_pages={set_show_sub_pages}
            refresh_flags={refresh_flags} set_refresh_flag={set_refresh_flag}

            toggle_sidebar={toggle_sidebar}
            set_toggle_sidebar={set_toggle_sidebar}
            device_type={device_type}
            set_device_type={set_device_type}

            />
    
    )
}


const PaneHeader = ({ token, set_token, set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, refresh_flags, set_refresh_flag, toggle_sidebar, set_toggle_sidebar, device_type, set_device_type }) => {


    //handle clicking settings button
    const show_settings = () => {
        //go to the account settings page
        SetActivePanelButton("Account Settings", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages)

    }






    return (

        <div className="pane_header">

            <img className="pane_header_logo_img" alt="StrichSuite Logo" src="https://strichsuite.com/images/strich.png" onClick={() => SetActivePanelButton("Dashboard", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages)} />
            

            <div className="pane_header_account">


               

                {/* Button to display settings */}
                <div className="pane_header_account_details" title="Settings" onClick={() => show_settings()}>

                    <img className="log_out_img" src="https://designers.azeeda.com/images/settings_icon.png" alt="Settings" />

                </div>


                {/* Button to log out on desktop or menu button for mobile */}

                {device_type === "desktop" ?

                    <div className="pane_header_account_details" title="Log Out" onClick={() => user_log_out_dialog(token, set_token, set_active_panel)}>

                        <img className="log_out_img" src="https://designers.azeeda.com/images/log_out_icon.png" alt="Log Out" />

                    </div>

                    : 

                    <div className="pane_header_account_details" title="Log Out" onClick={() => set_toggle_sidebar(!toggle_sidebar)}>
                            <img className="log_out_img" src="https://designers.azeeda.com/images/menu_icon.png" alt="Menu" />
                    </div>
                    
                    }

            </div>

        </div>

    )

};


