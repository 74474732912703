

export function get_profile_image(designer, classname) {

    // Define the default image URL
    let profile_image_url = "https://designers.azeeda.com/images/profile.png"

    // If the designer array has content and the designer has a profile image, overwrite it with that
    if (designer) {
        if (designer.profile_image) {
            profile_image_url = "https://designers.azeeda.com/images/profile/" + designer.profile_image + ".png"
        }
    }

    return (
        <img
            // Set the image source to the designer profile url calculated above
            src={profile_image_url}

            // Set the alt tag because it's good practice
            alt="Designer profile"

            // Set the className tag for CSS
            className={classname}

            // Handle the error if there is one
            // currentTarget is part of the event handling that refers to itself (in this case, the img tag)
            onError={({ currentTarget }) => {

                // Set the 'on error' event to null to prevent it firing again
                currentTarget.onerror = null

                // Set the image source to our backup image
                currentTarget.src = "https://designers.azeeda.com/images/profile.png"
            }}

        />
    )
};



