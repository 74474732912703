
import {
    get_profile_image
} from './user_details';

import {
    handle_bottom_page_scroll_arrow
} from './refresh_page';

import {
    user_log_out_dialog
} from './login';

import {
    set_cookie
} from './functions_cookies';



//switch to the active panel but don't highlight the sidebar button - there isn't one
export function SetActivePanel(button_name, set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, device_type) {

    return (

        <SetActivePanelFunction button_name={button_name} set_active_panel={set_active_panel} scrollable_bottom_arrow_divRef={scrollable_bottom_arrow_divRef} set_show_bottom_page_scroll_arrow={set_show_bottom_page_scroll_arrow} set_show_sub_pages={set_show_sub_pages} toggle_sidebar={toggle_sidebar} device_type={device_type} />
        
        )
}

//switch to the active panel but don't highlight the sidebar button - there isn't one
const SetActivePanelFunction = ({ button_name, set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, device_type }) => {


    //set the active panel to the button/page name
    set_active_panel(button_name);

    //if there's sub pages then show them
    if (set_show_sub_pages) {
        set_show_sub_pages(button_name)
    };

    //show the scroll to bottom button if the page has needs to scroll
    handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type);

}



//switch to the active panel and highlight the sidebar button
export function SetActivePanelButton(
    button_name,
    set_active_panel,
    scrollable_bottom_arrow_divRef,
    set_show_bottom_page_scroll_arrow,
    set_show_sub_pages,
    toggle_sidebar,
    set_toggle_sidebar,
    device_type) {

    //set the active panel to the button/page name
    set_active_panel(button_name);

    //set a cookie for the active panel
    set_cookie("active_panel_cookie", button_name, "1");

    //if there's sub pages then show them
    if (set_show_sub_pages) {
        set_show_sub_pages(button_name)
    };

    //highlight the button if it's been selected
    set_button_colour(button_name);


    //function to highlight side button that has been clicked
    function set_button_colour(button_name) {

        //get array of all buttons in a div with id = sidebar_menu_buttons
        //then change the background colour of all buttons in that array (button) to #fff (not highlighted)
        //and unbold the title

        Array.from(document.querySelectorAll("#sidebar_menu_buttons button"), button => {
            button.style.backgroundColor = "#fff";
            button.style.fontWeight = "normal";
        });

        //try this instead to see if it works - fix error in project command window
        //Array.from(document.querySelectorAll("#sidebar_menu_buttons button")).forEach(button => {
        //    button.style.backgroundColor = "#fff";
        //    button.style.fontWeight = "normal";
        //});

        //then change the background colour of the clicked button to #f3f3f3 (highlighted whilst on that page)
        //and bold the title
        const button = document.getElementById(button_name);
        button.style.background = '#e9f5f3';
        button.style.fontWeight = 'bold';
    }

    //if they're clicked to add a design
    switch (button_name) {

        default:
        // No action to take
    }

    //show the scroll to bottom button if the page has needs to scroll
    handle_bottom_page_scroll_arrow(scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, toggle_sidebar, device_type);

    //if it's a mobile device then close the sidebar menu when they click on a menu item
    if (device_type === "mobile") {

        //close the sidebar
        set_toggle_sidebar(false)

    };

}







//the pane side bar menu
export function pane_sidebar(set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, show_sub_pages, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type, set_device_type, token, set_token) {



    return (

        <PaneSidebar set_active_panel={set_active_panel} scrollable_bottom_arrow_divRef={scrollable_bottom_arrow_divRef} set_show_bottom_page_scroll_arrow={set_show_bottom_page_scroll_arrow} show_sub_pages={show_sub_pages} set_show_sub_pages={set_show_sub_pages} toggle_sidebar={toggle_sidebar} set_toggle_sidebar={set_toggle_sidebar} device_type={device_type} set_device_type={set_device_type} token={token} set_token={set_token} />

    )
}

//the pane side bar menu
const PaneSidebar = ({ set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, show_sub_pages, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type, set_device_type, token, set_token }) => {



    return (

        <div className="pane_sidebar" id={(toggle_sidebar) ? "open" : "closed" } >

            {device_type === "desktop" ? 
                <button className="toggle_sidebar" id="toggle_sidebar" onClick={() => set_toggle_sidebar(!toggle_sidebar)}>{(toggle_sidebar) ? "<" : ">"}</button>
                :
                ""
            }


            <div className="pane_sidebar_profile" id={(toggle_sidebar) ? "open" : "closed"} onClick={() => SetActivePanelButton("Designer", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type)} >
                <div className="pane_sidebar_profile_img_and_name">
                    {get_profile_image(null, "pane_sidebar_profile_img")}
                    <p className="pane_sidebar_profile_name">{(toggle_sidebar) ? "Welcome" : ""}</p>
                </div>
            </div>

            <div id="sidebar_menu_buttons">
                <button id="Dashboard" className="button_sidebar" onClick={() => SetActivePanelButton("Dashboard", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type)}>
                    <img className="button_sidebar_icons" alt="Home" src="https://designers.azeeda.com/images/home_icon.png" />
                    <span className="button_sidebar_title">Dashboard</span>
                </button>

                <button id="AI" className="button_sidebar" onClick={() => SetActivePanelButton("AI", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type)}>
                    <img className="button_sidebar_icons" alt="Earnings" src="https://designers.azeeda.com/images/earnings_icon.png" />
                    <span className="button_sidebar_title">AI</span>
                </button>

                <br />
                <br />
                <br />

                <button id="Account Settings" className="button_sidebar" onClick={() => SetActivePanelButton("Account Settings", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type)}>
                    <img className="button_sidebar_icons" alt="Account Settings" src="https://designers.azeeda.com/images/account_settings_icon.png" />
                    <span style={{ fontSize: 'small' }} className="button_sidebar_title">Account Settings</span>
                </button>

                <button id="Contact" className="button_sidebar" onClick={() => SetActivePanelButton("Contact", set_active_panel, scrollable_bottom_arrow_divRef, set_show_bottom_page_scroll_arrow, set_show_sub_pages, toggle_sidebar, set_toggle_sidebar, device_type)}>
                    <img className="button_sidebar_icons" alt="Contact" src="https://designers.azeeda.com/images/tech_support_contact_icon.png" />
                    <span style={{ fontSize: 'small' }} className="button_sidebar_title">Contact</span>
                </button>

            </div>


            {device_type === "mobile" ?

                <div>
                                    
                    <button className="button_sidebar" title="Log Out" onClick={() => user_log_out_dialog(token, set_token, set_active_panel)}>
                        <img className="button_sidebar_icons" alt="Log Out" src="https://designers.azeeda.com/images/log_out_icon_mobile.png" />
                        <span className="button_sidebar_title" style={{ fontSize: 'small' }}>Log Out</span>
                    </button>

                </div>

                :

                ""
            }


        </div>
    )

};











