





export function panel_contact_tech_support() {

	
	return (

		<div id="panel_contact_tech_support" className="panels_with_header">

			<div className="panel_header_bar panel_header_background_turquoise"><p>Technical Support Contact</p></div>

			<div className="panel_contents word_break">

				<p>At StrichSuite, we strive to provide you with the best possible user experience on our portal. However, we understand that issues can sometimes arise. That's why we have a dedicated tech support contact to assist you with any problems you may encounter.</p>
				<br />
				<p>His name is Joe and he would love to hear from you.</p>
				<br />

				<br />

				<span style={{ fontSize: 'larger', marginLeft: '20px' }}>
					<a href="mailto:joe@notgoingtoreply.com">
						<b>joe@notgoingtoreply.com</b></a>
				</span>

				<br />
				<br />
				


			</div>

		</div>

	)

}