import {
    are_arrays_identical
}
    from './functions_general';

import {
    get_account_details,
    get_queue,
    get_ai_to_process,
    get_ai_styles
}
    from './api_strich_calls';



export function refresh_data (
    token,
    set_token,
    refresh_flags,
    set_refresh_flag,
    changed_flags,
    set_changed_flag,
    account_details,
    set_account_details,
    ai_queue,
    set_ai_queue,
    ai_to_process,
    set_ai_to_process,
    set_ai_styles
    ) {


    //check if the portal is offline - if so exit
    if (token === "offline") {
        return
    }

    // Ok, the below can't all be updated and then the flags set at once.
    // Once you set a state (such as designer) then it prompts a refresh
    // If you haven't set the refresh flag then it will refresh and set that state again
    // It's probably doing it twice as it is (render when setting designer then rendering again when setting the refresh flag)

    //console.log(changed_flags.unsettled_earnings)


    // Only update these if we don't have a token - i.e. are at the login screen
    // Current the earnings ticker call is disabled because we're not showing it on the login screen
    if (!token) {

        // Refresh the earnings ticker
        if (refresh_flags.earnings_ticker) {
            return
        }

        // Bug out even if there's nothing to refresh
        // If we dont' have a token, all the following calls will fail because we're not logged in
        return

    }

    // Refresh the account details
    if (refresh_flags.account_details) {
        set_refresh_flag({ ...refresh_flags, account_details: false })
        get_account_details(token, set_account_details)
        return
    }

    // Refresh the account details
    if (refresh_flags.ai_queue) {
        set_refresh_flag({ ...refresh_flags, ai_queue: false })
        get_queue(token, set_ai_queue)
        return
    }

    // Refresh the account details
    if (refresh_flags.set_ai_to_process) {
        set_refresh_flag({ ...refresh_flags, set_ai_to_process: false })
        get_ai_to_process(token, set_ai_to_process)
        return
    }


    // Refresh the account details
    if (refresh_flags.ai_styles) {
        set_refresh_flag({ ...refresh_flags, ai_styles: false })
        get_ai_styles(token, set_ai_styles)
        return
    }

};